<template>
  <form class="login-right-form">
    <div class="wrap-img">
      <img class="login-left-logo" src="@/assets/images/doinsport/login.svg">
    </div>
    <div class="login-right-form-header">
      <h2>{{ $t('auth.login.title') }}</h2>
    </div>
    <div class="form-group username">
      <input
        v-model="username"
        :placeholder="$t('auth.login.username')"
        type="email"
      >
    </div>
    <div class="form-group password">
      <input
        v-model="password"
        :placeholder="$t('auth.login.password')"
        :type="showPassword ? 'text' : 'password'"
      >
      <i :class="!showPassword ? 'fa-eye-slash' : 'fa-eye'" class="fa" @click="showPassword = !showPassword" />
    </div>
    <div class="login-right-form-bottom">
      <label class="switch">
        <span class="text">{{ $t("auth.login.remember-me") }}</span>
        <input id="checkbox1" type="checkbox">
        <div class="slider round" />
      </label>
    </div>
    <div class="form-group login-right-form-submit">
      <button
        type="button"
        @click="signIn"
      >
        {{ $t("auth.login.connect") }}
      </button>
      <router-link class="password-recovery" :to="{ path: '/auth/password-recovery' }">
        {{ $t('auth.login.reset-password') }}
      </router-link>
    </div>
    <b-modal
      id="modal-login-error"
      :title="$t('auth.login.popup.login-error-title')"
      centered
      no-close-on-backdrop
      hide-footer
      class="theme-modal"
    >
      <p>{{ $t('auth.login.popup.login-error-description') }}</p>
      <div class="text-center">
        <button
          class="btn btn-primary btn-sm txt-white"
          type="button"
          data-dismiss="modal"
          @click="hidePopup"
        >
          {{ $t('auth.login.popup.close-btn') }}
        </button>
      </div>
    </b-modal>
    <b-modal
      id="modal-loader-login"
      size="sm"
      centered
      hide-footer
      hide-header
      hide-header-close
      class="theme-modal"
    >
      <div class="loader-box">
        <h6 class="mr-1">
          {{ $t('auth.login.popup.login-connecting-description') }}
        </h6>
        <div class="loader-19" />
      </div>
    </b-modal>
  </form>
</template>

<script>

export default {
  name: "Login",
  data () {
    return {
      username: "",
      password: "",
      showPassword: false,
    };
  },
  computed: {
    hostName () {
      return window.location.hostname;
    },

    logo () {
      const isMouratoglou = this.hostName === process.env.VUE_APP_MOURATOGLOU_HOSTNAME;

      return isMouratoglou ? { "doinsport-mouratoglou-background": true } : { "doinsport-background": true };
    },
  },
  created () {
    this.username = "";
    this.password = "";
  },
  mounted () {
    window.addEventListener("keypress", this.singInEnterOrigin, false);
  },
  beforeDestroy () {
    window.removeEventListener("keypress", this.singInEnterOrigin);
  },
  methods: {
    singInEnterOrigin (e) {
      if (e.key === "Enter") {
        this.signIn();
      }
    },
    hidePopup () {
      this.$bvModal.hide("modal-login-error");
    },
    signIn () {
      this.$bvModal.show("modal-login-error");

      const username = this.username;
      const password = this.password;

      this.$store.commit("auth/setConnecting", true);

      this.$store.dispatch("auth/login", {
        username,
        password,
      })
        .catch(() => {
          this.$nextTick(() => {
            this.$bvModal.show("modal-login-error");
          });
        })
        .finally(()=> this.$store.commit("auth/setConnecting", false));
    },
  },
};
</script>
