import { isNotUndefinedAndNotNull } from "@/utils/classes";

export const defaultLocale = "fr";

export const localeOptions = [
  {
    id: "en",
    name: "English",
  },
  {
    id: "fr",
    name: "French",
  },
  {
    id: "it",
    name: "Italien",
  },
  {
    id: "nl",
    name: "Dutch",
  },
  {
    id: "de",
    name: "German",
  },
];

export const NEW_FEATURES = ["data.menu.marketing.communication", "data.menu.marketing.sms"];

export const isNewFeature = (label) => isNotUndefinedAndNotNull(NEW_FEATURES.find(el => el === label));
